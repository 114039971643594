import { useRouter } from "next/router";
import { useEffect, useRef } from "react";

import { useAuth } from "@/contexts/AuthContext";
import { signInPath, signUpPath } from "@/utils/path";

export const useAuthRedirectEffect = () => {
  const { isLoggedIn, isCheckedAuth } = useAuth();

  const router = useRouter();
  const routerRef = useRef(router);
  useEffect(() => {
    if (
      isCheckedAuth &&
      !isLoggedIn &&
      !(routerRef.current.pathname === signUpPath() || routerRef.current.pathname === signInPath())
    ) {
      routerRef.current.replace(signInPath());
    }
  }, [isLoggedIn, isCheckedAuth]);
};
