import { CommonApiInterface, GuarantorApiInterface } from "@rakushifu/hrms-modules/dist/openapi";
import { ReactNode, createContext, useContext } from "react";

import { UserApiInterface } from "@/gateways";

const GatewayContext = createContext<{
  userApiClient: UserApiInterface;
  commonApiClient: CommonApiInterface;
  guarantorApiClient: GuarantorApiInterface;
}>({
  userApiClient: {} as UserApiInterface,
  commonApiClient: {} as CommonApiInterface,
  guarantorApiClient: {} as GuarantorApiInterface,
});

interface Props {
  children: ReactNode;
  userApiClient: UserApiInterface;
  commonApiClient: CommonApiInterface;
  guarantorApiClient: GuarantorApiInterface;
}

export const GatewayProvider = ({
  children,
  userApiClient,
  commonApiClient,
  guarantorApiClient,
}: Props) => {
  return (
    <GatewayContext.Provider value={{ userApiClient, commonApiClient, guarantorApiClient }}>
      {children}
    </GatewayContext.Provider>
  );
};

export const useGateway = () => useContext(GatewayContext);
