import { useLocalStorage } from "@mantine/hooks";
import { GetServerSidePropsContext } from "next";
import { parseCookies } from "nookies";
import { useCallback } from "react";

import { HRMS_AUTH_COOKIE_NAME } from "@/constants";

export interface Auth {
  accessToken: string;
  uid: string;
  client: string;
}

const AUTH_KEYS = {
  accessToken: "hrms-frontend/auth/access-token",
  uid: "hrms-frontend/auth/uid",
  client: "hrms-frontend/auth/client",
};

export const parseServerAuth = (ctx: Pick<GetServerSidePropsContext, "req">): Auth | undefined => {
  const cookies = parseCookies(ctx);
  if (!cookies[HRMS_AUTH_COOKIE_NAME]) {
    return undefined;
  }
  try {
    const auth = JSON.parse(cookies[HRMS_AUTH_COOKIE_NAME]);
    return auth;
  } catch {
    return undefined;
  }
};

export const useClientAuth = () => {
  const [accessToken, setAcessToken, removeAccessToken] = useLocalStorage({
    key: AUTH_KEYS.accessToken,
    getInitialValueInEffect: false,
  });
  const [uid, setUid, removeUid] = useLocalStorage({
    key: AUTH_KEYS.uid,
    getInitialValueInEffect: false,
  });
  const [client, setClient, removeClient] = useLocalStorage({
    key: AUTH_KEYS.client,
    getInitialValueInEffect: false,
  });
  const setAuth = useCallback(
    (auth: Auth) => {
      setAcessToken(auth.accessToken);
      setUid(auth.uid);
      setClient(auth.client);
    },
    [setAcessToken, setUid, setClient]
  );
  const removeAuth = useCallback(() => {
    removeAccessToken();
    removeUid();
    removeClient();
  }, [removeAccessToken, removeUid, removeClient]);

  return { accessToken, uid, client, setAuth, removeAuth };
};
