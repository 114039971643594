export const COLORS = {
  black: "#444444",

  gray500: "#888888",
  gray400: "#bfbfbf",
  gray300: "#d6d6d6",
  gray200: "#e3e3e3",
  gray100: "#f5f5f5",
  gray50: "#fbfbfb",

  white: "#ffffff",

  red: "#f84e59",
  redPale: "#ffedef",

  green: "#00c182",
  greenPale: "#e6f9f3",

  rakushifuBlue: "#2d7ee0",
  rakushifuLightBlue: "#6faefa",
  rakushifuBluePale: "#eaf2fc",

  saturdayBlue: "#65a7d6",
};
