import "@/styles/globals.css";

import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import {
  AutocompleteProps,
  MantineProvider,
  NumberInputProps,
  PasswordInputProps,
  SelectProps,
  TextInputProps,
  TextareaProps,
} from "@mantine/core";
import { DatePickerProps } from "@mantine/dates";
import { NotificationsProvider } from "@mantine/notifications";
import { Hydrate, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import Head from "next/head";
import { ReactNode, useState } from "react";
import { pdfjs } from "react-pdf";

import ErrorBoundary from "@/components/ErrorBoundary";
import DefaultLayout from "@/components/layouts/DefaultLayout";
import { AuthProvider } from "@/contexts/AuthContext";
import { GatewayProvider } from "@/contexts/GatewayContext";
import { useClientAuth } from "@/gateways/authStore";
import { useAuthRedirectEffect } from "@/hooks/useAuthRedirectEffect";
import {
  createCommonApiClient,
  createGuarantorApiClient,
  createUserApiClient,
} from "@/infrastructures/apiClient";
import { AppPropsWithLayout } from "@/types";

config.autoAddCss = false;
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

const TextInputDefaultProps: Partial<TextInputProps> = {
  size: "md",
};
const NumberInputDefaultProps: Partial<NumberInputProps> = {
  size: "md",
};
const DatePickerDefaultProps: Partial<DatePickerProps> = {
  size: "md",
};
const SelectDefaultProps: Partial<SelectProps> = {
  size: "md",
};
const TextareaDefaultProps: Partial<TextareaProps> = {
  size: "md",
};
const PasswordInputDefaultProps: Partial<PasswordInputProps> = {
  size: "md",
};
const AutocompleteDefaultProps: Partial<AutocompleteProps> = {
  size: "md",
};

const MainProvider = ({ children }: { children: ReactNode }) => {
  const auth = useClientAuth();
  const userApiClient = createUserApiClient(auth);
  const commonApiClient = createCommonApiClient(auth);
  const guarantorApiClient = createGuarantorApiClient();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
  );
  return (
    <AuthProvider userApiClient={userApiClient}>
      <GatewayProvider
        userApiClient={userApiClient}
        commonApiClient={commonApiClient}
        guarantorApiClient={guarantorApiClient}
      >
        <QueryClientProvider client={queryClient}>
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={{
              components: {
                TextInput: { defaultProps: TextInputDefaultProps },
                NumberInput: { defaultProps: NumberInputDefaultProps },
                DatePicker: { defaultProps: DatePickerDefaultProps },
                Select: { defaultProps: SelectDefaultProps },
                Textarea: { defaultProps: TextareaDefaultProps },
                PasswordInput: { defaultProps: PasswordInputDefaultProps },
                Autocomplete: { defaultProps: AutocompleteDefaultProps },
              },
              fontFamily:
                'Avenir, "Helvetica Neue", Helvetica, Arial, "Hiragino Kaku Gothic ProN", YuGothic',
              black: "#444",
              headings: {
                sizes: {
                  h2: {
                    fontSize: 18,
                    lineHeight: 1,
                  },
                  h3: {
                    fontSize: 14,
                    lineHeight: 1,
                  },
                },
              },
            }}
          >
            <NotificationsProvider position="top-center">
              <ReactQueryDevtools />
              {children}
            </NotificationsProvider>
          </MantineProvider>
        </QueryClientProvider>
      </GatewayProvider>
    </AuthProvider>
  );
};

function MyApp({ Component, pageProps }: AppPropsWithLayout<{ dehydratedState: any }>) {
  useAuthRedirectEffect();
  const getLayout = Component.getLayout ?? ((page) => <DefaultLayout>{page}</DefaultLayout>);
  const { dehydratedState, ...props } = pageProps;
  return (
    <>
      <Head>
        <title>らくしふ労務管理</title>
        <meta name="robots" content="noindex" />
      </Head>
      <ErrorBoundary>
        <MainProvider>
          <Hydrate state={dehydratedState}>{getLayout(<Component {...props} />)}</Hydrate>
        </MainProvider>
      </ErrorBoundary>
    </>
  );
}

export default MyApp;
