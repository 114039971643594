import { V1UserSignInResponse } from "@rakushifu/hrms-modules/dist/openapi";
import axios from "axios";
import { ReactNode, createContext, useCallback, useContext, useEffect, useState } from "react";

import { HRMS_AUTH_HEADER_KEYS } from "@/constants";
import { ErrorResponse, UserApiInterface } from "@/gateways";
import { useClientAuth } from "@/gateways/authStore";

interface Auth {
  isLoggedIn: boolean;
  isCheckedAuth: boolean;
  login: (uid: string, password: string, token?: string) => Promise<string | undefined>;
  logout: () => Promise<string | undefined>;
}

const AuthContext = createContext<Auth>({} as Auth);

interface Props {
  children: ReactNode;
  userApiClient: UserApiInterface;
}

export const AuthProvider = ({ children, userApiClient }: Props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isCheckedAuth, setIsCheckedAuth] = useState(false);
  const clientAuth = useClientAuth();

  const login = useCallback(
    async (uid: string, password: string, token?: string) => {
      try {
        const { headers } = await axios.post<V1UserSignInResponse>(
          "/api/auth/sign-in",
          {
            uid,
            password,
            token,
          },
          { withCredentials: true }
        );
        clientAuth.setAuth({
          accessToken: headers[HRMS_AUTH_HEADER_KEYS.accessToken],
          uid: headers[HRMS_AUTH_HEADER_KEYS.uid],
          client: headers[HRMS_AUTH_HEADER_KEYS.client],
        });
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          // TODO: axiosの次のバージョンで isAxiosError<ErrorResponse> ができるようになる
          return (e.response.data as ErrorResponse).detail || "";
        } else {
          return (e as Error).toString();
        }
      }
      setIsLoggedIn(true);
    },
    [clientAuth]
  );

  const logout = useCallback(async () => {
    try {
      await axios.delete("/api/auth/sign-out", {
        withCredentials: true,
        headers: {
          [HRMS_AUTH_HEADER_KEYS.accessToken]: clientAuth.accessToken,
          [HRMS_AUTH_HEADER_KEYS.client]: clientAuth.client,
          [HRMS_AUTH_HEADER_KEYS.uid]: clientAuth.uid,
        },
      });
      clientAuth.removeAuth();
      setIsLoggedIn(false);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        // TODO: axiosの次のバージョンで isAxiosError<ErrorResponse> ができるようになる
        return (e.response.data as ErrorResponse).detail || "";
      } else {
        return (e as Error).toString();
      }
    }
  }, [clientAuth]);

  const checkAuthOnServer = useCallback(async () => {
    try {
      await userApiClient.validateTokenAsUser();
      setIsLoggedIn(true);
    } finally {
      setIsCheckedAuth(true);
    }
  }, [userApiClient]);
  useEffect(() => {
    if (!isCheckedAuth) {
      checkAuthOnServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckedAuth]);

  const auth: Auth = { isLoggedIn, isCheckedAuth, login, logout };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
