import { COLORS } from "@/styles/colors";

import { Box, Header as MantineHeader } from "@mantine/core";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
}

const Header = ({ children }: Props) => {
  return (
    <MantineHeader height={56}>
      <Box
        sx={{
          height: 56,
          backgroundColor: COLORS.white,
          boxShadow: "0px 3px 4px rgba(0, 0, 0, 0.1), 0px 0px 4px rgba(0, 0, 0, 0.12)",
        }}
      >
        {children}
      </Box>
    </MantineHeader>
  );
};

export default Header;
