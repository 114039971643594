import { Container } from "@mantine/core";
import Image from "next/image";
import { ReactNode } from "react";

import Header from "@/components/atoms/Header";

interface Props {
  rightSection?: ReactNode;
}

const LogoHeader = ({ rightSection }: Props) => {
  return (
    <Header>
      <Container
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Image
          src="/images/logo.png"
          height={32}
          width={155}
          alt="らくしふ労務管理"
          style={{
            maxWidth: "100%",
            height: "auto",
            objectFit: "contain",
          }}
        />
        {rightSection}
      </Container>
    </Header>
  );
};

export default LogoHeader;
