import { COLORS } from "@/styles/colors";

import { Stack, Text } from "@mantine/core";
import Image from "next/image";
import Link from "next/link";
import { Component, ErrorInfo, ReactNode } from "react";

import WhiteButton from "@/components/atoms/WhiteButton";

interface Props {
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Stack pt={80} align="center">
          <Image
            src="/images/penguin.png"
            height={164}
            width={101}
            alt=""
            style={{
              maxWidth: "100%",
              height: "auto",
              objectFit: "contain",
            }}
          />
          <Text align="center" size="lg" weight="bold" sx={{ color: COLORS.rakushifuBlue }}>
            一時的なエラーが発生しました
            <br />
            しばらく経ってからアクセスしてください
          </Text>
          <WhiteButton component={Link} href="/" sx={{ width: 200 }}>
            らくしふ労務管理に戻る
          </WhiteButton>
        </Stack>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
