import { COLORS } from "@/styles/colors";

import { Button, ButtonProps } from "@mantine/core";
import { PolymorphicComponentProps } from "@mantine/utils";
import { ComponentPropsWithoutRef } from "react";

const WhiteButton = ({
  sx,
  ...props
}: ButtonProps & ComponentPropsWithoutRef<"button"> & PolymorphicComponentProps<any>) => {
  return (
    <Button
      variant="white"
      color="dark"
      sx={{
        borderColor: COLORS.gray300,
        fontWeight: "normal",
        ...sx,
      }}
      {...props}
    />
  );
};

export default WhiteButton;
