import {
  CommonApi,
  Configuration,
  GuarantorApi,
  UserApi,
  V1UserSignInRequestBody,
} from "@rakushifu/hrms-modules/dist/openapi";
import axios, { AxiosInstance, AxiosRequestTransformer, AxiosResponseTransformer } from "axios";
import camelcaseKeys from "camelcase-keys";
import FormData from "form-data";
import snakecaseKeys from "snakecase-keys";

import { HRMS_AUTH_HEADER_KEYS } from "@/constants";
import { Auth } from "@/gateways/authStore";
import { API_HOST } from "@/utils/config";

const transformRequestCamelKeyToSnakeKey: AxiosRequestTransformer = (data: any) => {
  if (data instanceof FormData) {
    return data;
  }
  if (data) {
    return JSON.stringify(snakecaseKeys(JSON.parse(data), { deep: true }));
  }
  return data;
};

const transformResponseSnakeKeyToCamelKey: AxiosResponseTransformer = (data: any) => {
  return camelcaseKeys(data, { deep: true });
};

const conf = new Configuration({
  basePath: "",
  baseOptions: {},
});

class ExtendedUserApi extends UserApi {
  constructor(configuration: Configuration, basePath: string, axios: AxiosInstance) {
    super(configuration, basePath, axios);
  }

  async signIn(params: V1UserSignInRequestBody) {
    const res = await this.signInAsUser(params);
    return {
      ...res.data,
      accessToken: res.headers[HRMS_AUTH_HEADER_KEYS.accessToken],
      uid: res.headers[HRMS_AUTH_HEADER_KEYS.uid],
      client: res.headers[HRMS_AUTH_HEADER_KEYS.client],
    };
  }
}

export const createUserApiClient = (auth?: Auth) => {
  const axiosClient = createAxiosClient(auth);
  return new ExtendedUserApi(conf, API_HOST, axiosClient);
};

export const createCommonApiClient = (auth?: Auth) => {
  const axiosClient = createAxiosClient(auth);
  return new CommonApi(conf, API_HOST, axiosClient);
};

export const createGuarantorApiClient = () => {
  const axiosClient = createAxiosClient();
  return new GuarantorApi(conf, API_HOST, axiosClient);
};

const createAxiosClient = (auth?: Auth) =>
  axios.create({
    transformRequest: [
      transformRequestCamelKeyToSnakeKey,
      ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
    ],
    transformResponse: [
      ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
      transformResponseSnakeKeyToCamelKey,
    ],
    headers:
      auth?.accessToken && auth?.uid && auth?.client
        ? {
            [HRMS_AUTH_HEADER_KEYS.accessToken]: auth.accessToken,
            [HRMS_AUTH_HEADER_KEYS.uid]: auth.uid,
            [HRMS_AUTH_HEADER_KEYS.client]: auth.client,
          }
        : {},
  });
