import { APP_ENV, APP_ENVS } from "@/utils/env";

const API_HOSTS = {
  [APP_ENVS.DEV]: "http://localhost:23000",
  [APP_ENVS.STG]: "https://api.stg-hrms.rakushifu.com",
  [APP_ENVS.QA]: "https://api.stg-hrms.rakushifu.com",
  [APP_ENVS.PROD]: "https://api.hrms.rakushifu.com",
  [APP_ENVS.TEST]: "",
} as const;

const SENTRY_DSNS = {
  [APP_ENVS.DEV]: "",
  [APP_ENVS.STG]:
    "https://0267744fbb52413e80bac7b6065187d9@o149839.ingest.sentry.io/4504521470509056",
  [APP_ENVS.QA]:
    "https://0267744fbb52413e80bac7b6065187d9@o149839.ingest.sentry.io/4504521470509056",
  [APP_ENVS.PROD]:
    "https://63abd2c8c1a24d3d94f24cfe92a36a7f@o149839.ingest.sentry.io/4504848778264576",
  [APP_ENVS.TEST]: "",
};

export const API_HOST = API_HOSTS[APP_ENV];
export const SENTRY_DSN = SENTRY_DSNS[APP_ENV];
