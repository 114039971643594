export const APP_ENVS = {
  DEV: "dev",
  STG: "stg",
  QA: "qa",
  PROD: "prod",
  TEST: "test",
} as const;

export const APP_ENV = process.env.APP_ENV as typeof APP_ENVS[keyof typeof APP_ENVS];

export const BASIC_AUTH_ID = process.env.BASIC_AUTH_ID as string;
export const BASIC_AUTH_PASSWORD = process.env.BASIC_AUTH_PASSWORD as string;
