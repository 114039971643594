import { COLORS } from "@/styles/colors";

import { AppShell, AppShellProps, Container } from "@mantine/core";
import { ReactNode } from "react";

import LogoHeader from "@/components/molecules/LogoHeader";

interface Props {
  children: ReactNode;
  header?: AppShellProps["header"];
  footer?: AppShellProps["footer"];
}

const DefaultLayout = ({ children, header = <LogoHeader />, footer }: Props) => {
  return (
    <AppShell
      styles={{ main: { backgroundColor: COLORS.gray100 } }}
      header={header}
      footer={footer}
    >
      <Container p={0} sx={{ maxWidth: 375 }}>
        {children}
      </Container>
    </AppShell>
  );
};

export default DefaultLayout;
